.inputStyle {
    border-radius: 7px !important;
    border: 0px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    padding: 12px !important;
    background: #ffff;
    font-size: 20px;
    box-shadow: 0px 10px #FADEDA;
    color: black;
  }